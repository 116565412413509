<template>
  <div class="centerbox">
    <el-menu router :default-active="$route.path" class="" mode="horizontal">
      <el-menu-item index="/persionSet">个人信息</el-menu-item>
    </el-menu>
    <div class="ibx-content cenform">
      <el-row>
        <el-col :sm="18" :md="16" :lg="16">
          <el-form
            ref="form"
            :model="form"
            :rules="formRules"
            label-width="70px"
            size="small"
          >
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              {{ form.phone }}
              <el-link
                type="primary"
                :underline="false"
                @click="verifyPhoneHandle"
                >更换手机号</el-link
              >
            </el-form-item>
            <el-form-item label="E-mail">
              {{ form.email }}
              <el-link
                type="primary"
                :underline="false"
                @click="verifyEmailHandle"
                >更换邮箱</el-link
              >
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="form.password"  type="password"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="intro">
              <el-input
                type="textarea"
                style="font-size: 13px"
                :rows="3"
                v-model="form.intro"
              ></el-input>
            </el-form-item>
            <el-form-item class="noborder">
              <el-button type="primary" size="small" @click="saveUserPerson"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="绑定手机号码"
      :visible.sync="dialogPhoneVisible"
      width="40%"
    >
      <el-form
        ref="verifyForm"
        :model="verifyForm"
        :rules="formRules"
        label-width="0"
      >
        <el-form-item prop="phone">
          <el-input placeholder="输入手机号" v-model="verifyForm.phone">
            <template slot="prepend">中国+86</template>
          </el-input>
        </el-form-item>
        <el-form-item class="valid-code" prop="verifycode">
          <el-input placeholder="输入验证码" v-model="verifyForm.verifycode">
            <template slot="append">
              <div class="btn" v-if="!sendCode" @click="sendValidCode">
                发送验证码
              </div>
              <div class="btn" v-else>{{ second }}秒后重试</div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPhoneVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserPhone">确 定</el-button>
      </span>
    </el-dialog>
        <el-dialog
      title="绑定邮箱"
      :visible.sync="dialogPhoneVisible2"
      width="40%"
    >
      <el-form
        ref="verifyForm"
        :model="verifyForm2"
        :rules="formRules"
        label-width="0"
      >
        <el-form-item prop="email">
          <el-input placeholder="输入邮箱" v-model="verifyForm2.email">
            <!-- <template slot="prepend">中国+86</template> -->
          </el-input>
        </el-form-item>
        <el-form-item class="valid-code" prop="verifycode">
          <el-input placeholder="输入验证码" v-model="verifyForm2.verifycode">
            <template slot="append">
              <div class="btn" v-if="!sendCode" @click="sendValidCodeEmail">
                发送验证码
              </div>
              <div class="btn" v-else>{{ second }}秒后重试</div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPhoneVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserEmail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getUserPerson, checkUserPerson, getPhoneSms, setUserPhone,setUserEmail, saveUserPerson,getEmailSms } from "@/api/project.js";
export default {
  name: "PersonMain",
  data() {
    var checkUsername = (rule, value, callback) => {
      this.checkRequestField({ username: value }).then((res) => {
        if (res) callback();
        else callback(new Error("请输入正确的用户名"));
      });
    };
    var checkEmail = (rule, value, callback) => {
      this.checkRequestField({ email: value }).then((res) => {
        if (res) callback();
        else callback(new Error("请输入正确的邮箱地址"));
      });
    };
    var checkPhone = (rule, value, callback) => {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    return {
      avatar: "",
      userinfo: {},
      dialogPhoneVisible: false,
      dialogPhoneVisible2: false,
      form: {
        username: "",
        phone: "",
        email: "",
        password: "",
        intro: "",
      },
      group: [],
      formRules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { validator: checkUsername, trigger: "blur" },
        ],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        intro: [{ required: true, message: "简介不能为空", trigger: "blur" }],
        verifycode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
      },
      sendCode: false,
      second: 0,
      playId: null,
      verifyForm: {
        phone: "",
        verifycode: "",
      },
      verifyForm2: {
        email: "",
        verifycode: "",
      },
      validMessage: "发送验证码",
    };
  },
  beforeCreate() {},
  created() {
    this.getUserPerson();
  },
  computed: {
    proid() {
      return this.$route.query.id || ""; //当前项目id
    },
  },
  methods: {
    async checkRequestField(data) {
      const result = await checkUserPerson(data).then((res) => res);
      return result;
    },
    async getUserPerson() {
      try {
        const user = await getUserPerson().then((res) => res.data);
        this.avatar = user.avatar;
        this.userinfo = user.userinfo;

        let { Username, Phone, Email, Intro } = user.userinfo;
        this.form.username = Username;
        this.form.phone = Phone;
        this.form.email = Email;
        this.form.intro = Intro;
      } catch (e) {}
    },
    async sendValidCode() {
      let _this = this;
      this.$refs["verifyForm"].validateField("phone", async function (err) {
        if (!err) {
          _this.sendCode = true;
          _this.second = 60;
          const result = await getPhoneSms({
             phone: _this.verifyForm.phone
          });
          if (result.status == 200) {
            _this.playId = setInterval(() => {
              _this.second -= 1;
              if (_this.second == 0) {
                clearInterval(_this.playId);
                _this.sendCode = false;
              }
            }, 1000);
          } else {
            _this.$message({
              showClose: true,
              message: result.message || "获取验证码失败",
              type: "error",
            });
            _this.sendCode = false;
          }
        }
      });
    },
    async sendValidCodeEmail() {
      let _this = this;
      this.$refs["verifyForm"].validateField("email", async function (err) {
        if (!err) {
          _this.sendCode = true;
          _this.second = 60;
          const result = await getEmailSms({
              email:_this.verifyForm2.email
          });
          if (result.status == 200) {
            _this.playId = setInterval(() => {
              _this.second -= 1;
              if (_this.second == 0) {
                clearInterval(_this.playId);
                _this.sendCode = false;
              }
            }, 1000);
          } else {
            _this.$message({
              showClose: true,
              message: result.message || "获取验证码失败",
              type: "error",
            });
            _this.sendCode = false;
          }
        }
      });
    },
    async saveUserPhone() {
      try {
        const valid = await this.$refs["verifyForm"]
          .validate()
          .then((res) => res)
          .catch((err) => err);
        if (!valid) return;
        const { verifycode, phone } = this.verifyForm;
        const result = await setUserPhone({
          verifycode,
          phone,
        });
        if (result.status == 200) {
          this.form.phone = phone;
          this.dialogPhoneVisible = false;
          this.$message({
          message: result.message,
          type: 'success'
        });
        } else {
          this.$message({
            showClose: true,
            message: result.message || "绑定手机号失败",
            type: "error",
          });
        }
      } catch (e) {}
    },
    async saveUserEmail() {
      try {
        const valid = await this.$refs["verifyForm"]
          .validate()
          .then((res) => res)
          .catch((err) => err);
        if (!valid) return;
        const { verifycode, email } = this.verifyForm2;
        const result = await setUserEmail({
          verifycode,
          email,
        });
        if (result.status == 200) {
          this.form.email = email;
          this.dialogPhoneVisible2 = false;
          this.$message({
          message: result.message,
          type: 'success'
        });
        } else {
          this.$message({
            showClose: true,
            message: result.message || "绑定邮箱失败",
            type: "error",
          });
        }
      } catch (e) {}
    },
    verifyPhoneHandle() {
      this.dialogPhoneVisible = true;
      this.verifyForm.phone = this.form.phone;
      this.verifyForm.verifycode = "";
      this.sendCode = false;
      this.second = 0;
    },
    verifyEmailHandle() {
      this.dialogPhoneVisible2 = true;
      this.verifyForm2.email = this.form.email;
      this.verifyForm2.verifycode = "";
      this.sendCode = false;
      this.second = 0;
    },
    async saveUserPerson() {
      try {
        const valid = await this.$refs["form"].validate();
        if (!valid) return;
        let { username, password, intro, email } = this.form;
        password = this.$md5(password);
        let data = { username, password, intro, email };
        const result = await saveUserPerson(data).then((res) => res);
        if (result.status == 200) {
          this.$message({
            showClose: true,
            message: result.message,
            type: "success",
          });
          location.reload();
        } else {
          this.$message({
            showClose: true,
            message: result.message,
            type: "error",
          });
        }
      } catch (e) {}
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  height: 100%;
  .el-container {
    // height: e("calc(100% - 70px)");
  }
  .el-main {
    background-color: #fff;
    padding: 15px;
  }
}

/deep/.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
.ibx-content {
  padding: 20px 0;
}
.centerbox {
  position: relative;
  margin: 0 20px;
  .topbox {
    border-bottom: 1px solid #e5e5e5;
    line-height: 40px;
    padding: 8px 0 8px 0;
    margin: 0 20px;
    .titleh2 {
      font-size: 16px;
    }
  }
}
</style>